
import { defineComponent } from 'vue'
import TmCarbonCopy from '@/components/shared/TmCarbonCopy.vue'

export default defineComponent({
  components: {
    TmCarbonCopy,
  },
  emits: ['show-coworkers'],
  setup() {
    const items = [
      {
        fullName: 'Randall Schinner',
        avatar: 'https://randomuser.me/api/portraits/men/97.jpg',
        avatarColor: 'purple',
      },
      {
        fullName: 'Clark Rippin I',
        avatar: 'https://randomuser.me/api/portraits/men/32.jpg',
        avatarColor: 'mint',
      },
      {
        fullName: 'Latoya Price',
        avatar: '',
        avatarColor: 'gray',
      },
    ]

    return {
      items,
    }
  },
})
