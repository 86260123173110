
import { defineComponent, ref, computed } from 'vue'
import TmFieldsContainer from '@/components/shared/tmFieldsContainer/TmFieldsContainer.vue'
import TmSystemInfo from '@/components/shared/TmSystemInfo.vue'
import {
  organizationsOptions, contactsStatusOptions, assigneeOptions, contactListsOptions, contactSegmentsOptions, tagsOptions
} from '@/definitions/_general/_data/optionsList'
import type { ListChip } from '@/definitions/shared/types'
import { utcZonesShort } from '@/definitions/_general/calendar/utc'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'
import { useBreakpoints } from '@/compositions/breakpoints'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  name: 'ContactsDetailsSidebarBody',
  components: {
    TmFieldsContainer,
    TmSystemInfo,
  },
  props: {
    isShared: {
      type: Boolean,
    },
    search: {
      type: String,
    },
  },
  emits: ['update:search'],
  setup(props) {
    const { isEmptyMode } = useModes()
    const { isMdMax } = useBreakpoints()
    const fullName = ref('Jennifer Crowford')
    const phones = ref(isEmptyMode.value
      ? []
      : [{ phone: '(990) 338-9983', type: 'Work' }])
    const emails = ref(isEmptyMode.value
      ? []
      : [{ email: 'j.crowford@mail.com', type: 'Work' }])
    const jobTitle = ref(isEmptyMode.value ? '' : 'Office manager')
    const socialMedia = ref(['facebook.com/textmagic', 'twitter.com/textmagic', 'linkedin.com/textmagic'])
    const organization = ref(isEmptyMode.value ? null : organizationsOptions[0])

    const statusValue = ref(contactsStatusOptions[0])
    const statusOptions = contactsStatusOptions

    const ownerValue = ref(isEmptyMode.value ? '' : assigneeOptions[3])

    const listsValue = ref<ListChip[]>([contactListsOptions[7], contactListsOptions[8], contactListsOptions[9], contactListsOptions[1]])
    const listsOptions = ref<ListChip[]>([...contactListsOptions])

    const segmentsOptions = ref(isEmptyMode.value ? [] : [...contactSegmentsOptions])
    const segmentsValue = ref(isEmptyMode.value ? [] : [...contactSegmentsOptions].slice(0, 2))

    const tagsValue = ref(tagsOptions.slice(0, 2))
    const timezoneCurrent = ref(isEmptyMode.value ? null : utcZonesShort[113].value)
    const country = ref(isEmptyMode.value ? null : phoneCountryVariant[0])

    const interactions = [
      {
        label: 'Total interactions',
        text: '32',
      },
      {
        label: 'Last interaction',
        text: '11 Jul, 3:14 am',
      },
      {
        label: 'Last outbound',
        text: '11 Jul, 3:14 am',
      },
      {
        label: 'Last inbound',
        text: '11 Jul, 3:14 am',
      },
    ]

    const systemInfo = [
      {
        label: 'Contact ID',
        text: '3225343',
      },
      {
        label: 'Created',
        text: '11 Jul 2021, 3:14 am',
      },
      {
        label: 'Created by',
        text: 'James Smith',
      },
      {
        label: 'Updated',
        text: 'Yesterday, 11:33 pm',
      },
      {
        label: 'Updated by',
        text: 'James Smith',
      },
    ]

    const commonProps = computed(() => {
      return {
        leftLabel: !isMdMax.value,
        search: props.search,
        inputContainerClass: isMdMax.value ? 'mx-n2' : '',
      }
    })

    return {
      isMdMax,
      fullName,
      phones,
      emails,
      jobTitle,
      socialMedia,
      organization,
      organizationsOptions,
      statusValue,
      statusOptions,
      ownerValue,
      assigneeOptions,
      listsValue,
      listsOptions,
      segmentsValue,
      segmentsOptions,
      tagsValue,
      tagsOptions,
      timezoneCurrent,
      country,
      interactions,
      systemInfo,
      commonProps,
    }
  },
})
