import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ec6b094"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "px-4 gt-md pt-5 pb-2"
}
const _hoisted_2 = { class: "px-6" }
const _hoisted_3 = {
  key: 1,
  class: "pa-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ticket_details_sidebar_coworkers = _resolveComponent("ticket-details-sidebar-coworkers")!
  const _component_details_sidebar_header = _resolveComponent("details-sidebar-header")!
  const _component_details_sidebar_search = _resolveComponent("details-sidebar-search")!
  const _component_ticket_details_sidebar_info = _resolveComponent("ticket-details-sidebar-info")!
  const _component_details_sidebar_activities = _resolveComponent("details-sidebar-activities")!
  const _component_details_sidebar_notes = _resolveComponent("details-sidebar-notes")!
  const _component_contacts_details_sidebar_body = _resolveComponent("contacts-details-sidebar-body")!
  const _component_search_no_results = _resolveComponent("search-no-results")!
  const _component_details_grid_sidebar = _resolveComponent("details-grid-sidebar")!

  return (_openBlock(), _createBlock(_component_details_grid_sidebar, {
    class: "ticket-details-sidebar",
    "sidebar-sections": _ctx.computedSidebarSections,
    "no-padding": "",
    "small-header": _ctx.isMdMax
  }, {
    "sidebar-header": _withCtx(() => [
      (!_ctx.showSearch)
        ? (_openBlock(), _createBlock(_component_details_sidebar_header, {
            key: 0,
            onShowSearch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSearch = true)),
            onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
          }))
        : (_openBlock(), _createBlock(_component_details_sidebar_search, {
            key: 1,
            search: _ctx.search,
            "onUpdate:search": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.search) = $event)),
            onHideSearch: _ctx.hideSearch
          }, null, 8, ["search", "onHideSearch"]))
    ]),
    "sidebar-section-ticket": _withCtx(() => [
      _createVNode(_component_ticket_details_sidebar_info, {
        "search-state": !!_ctx.search,
        "is-draft": _ctx.isDraft,
        "is-solved": _ctx.isSolved,
        "is-simulate-ticket": _ctx.isSimulateTicket
      }, null, 8, ["search-state", "is-draft", "is-solved", "is-simulate-ticket"])
    ]),
    "sidebar-section-recent-activities": _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_details_sidebar_activities, { activities: _ctx.detailsSidebarActivities }, null, 8, ["activities"])
      ])
    ]),
    "sidebar-section-contact-notes": _withCtx(() => [
      _createVNode(_component_details_sidebar_notes)
    ]),
    "sidebar-section-contact": _withCtx(() => [
      _createVNode(_component_contacts_details_sidebar_body)
    ]),
    default: _withCtx(() => [
      (_ctx.showCoworkersComputed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ticket_details_sidebar_coworkers, {
              onShowCoworkers: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('show-coworkers')))
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.search.length > 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_search_no_results, {
              bordered: "",
              size: "small"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["sidebar-sections", "small-header"]))
}