
import { computed, defineComponent, ref } from 'vue'
import TmFieldsContainer from '@/components/shared/tmFieldsContainer/TmFieldsContainer.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import { assigneeOptions, tagsOptions, ticketsStatusOptions } from '@/definitions/_general/_data/optionsList'
import { priorityVariant } from '@/definitions/_general/_data/priorityVariant'
import { getAssociateOptions } from '@/mock/associateCategories'
import TmSystemInfo from '@/components/shared/TmSystemInfo.vue'

export default defineComponent({
  components: {
    TmSystemInfo,
    TmFieldsContainer,
  },
  props: {
    isSimulateTicket: {
      type: Boolean,
    },
    isDraft: {
      type: Boolean,
    },
    searchState: {
      type: Boolean,
    },
    isSolved: {
      type: Boolean,
    },
  },
  setup(props) {
    const { isSmMax } = useBreakpoints()

    const commonProps = computed(() => {
      return {
        leftLabel: !isSmMax.value,
        inputContainerClass: isSmMax.value ? 'mx-n2' : '',
      }
    })

    const statusValue = computed(() => {
      if (props.isDraft) {
        return ticketsStatusOptions.find((e: any) => e.name === 'draft')
      }
      if (props.isSolved) {
        return ticketsStatusOptions.find((e: any) => e.name === 'solved')
      }
      return ticketsStatusOptions[0]
    })

    const statusOptions = ticketsStatusOptions
    const date = ref()

    const assigneeValue = ref(assigneeOptions[3])

    const priorityOptions = ref(priorityVariant)
    const priorityValue = ref(priorityOptions.value[0])

    const carbonCopyOptions = getAssociateOptions(['contacts'], true)
    const carbonCopy = ref(carbonCopyOptions.slice(0, 2))

    const tagsValue = ref(tagsOptions.slice(0, 2))

    const systemInfo = [
      {
        label: 'Ticket ID',
        text: '3225343',
      },
      {
        label: 'Inbox',
        text: 'support@company.com',
      },
      {
        label: 'Created',
        text: '11 Jul, 3:14 am',
      },
      {
        label: 'Last updated',
        text: 'Yesterday, 11:33 pm',
      },
      {
        label: 'Updated by',
        text: 'Robert Overit',
      },
    ]

    return {
      systemInfo,
      carbonCopy,
      carbonCopyOptions,
      priorityOptions,
      priorityValue,
      date,
      assigneeValue,
      assigneeOptions,
      statusValue,
      statusOptions,
      tagsValue,
      tagsOptions,
      commonProps,
    }
  },
})
