
import { defineComponent, ref, computed } from 'vue'
import DetailsGridSidebar from '@/components/shared/templates/details/DetailsGridSidebar.vue'
import DetailsSidebarHeader from '@/components/shared/templates/details/sidebar/DetailsSidebarHeader.vue'
import type { SidebarSectionType } from '@/components/shared/templates/details/DetailsGridSidebar.vue'
import ContactsDetailsSidebarBody from '@/components/pages/contacts/contactDetails/ContactsDetailsSidebarBody.vue'
import TicketDetailsSidebarInfo from '@/components/pages/tickets/details/sidebar/TicketDetailsSidebarInfo.vue'
import DetailsSidebarNotes from '@/components/shared/templates/details/sidebar/DetailsSidebarNotes.vue'
import TicketDetailsSidebarCoworkers from '@/components/pages/tickets/details/sidebar/TicketDetailsSidebarCoworkers.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import DetailsSidebarSearch from '@/components/shared/templates/details/sidebar/DetailsSidebarSearch.vue'
import DetailsSidebarActivities from '@/components/shared/templates/details/sidebar/DetailsSidebarActivities.vue'
import SearchNoResults from '@/components/shared/search/SearchNoResults.vue'
import { detailsSidebarActivities } from '@/definitions/tickets/data'

export default defineComponent({
  name: 'TicketDetailsSidebar',
  components: {
    SearchNoResults,
    DetailsSidebarActivities,
    DetailsSidebarSearch,
    TicketDetailsSidebarCoworkers,
    DetailsSidebarNotes,
    TicketDetailsSidebarInfo,
    ContactsDetailsSidebarBody,
    DetailsGridSidebar,
    DetailsSidebarHeader,
  },
  props: {
    isDraft: {
      type: Boolean,
    },
    isSimulateTicket: {
      type: Boolean,
    },
    isSolved: {
      type: Boolean,
    },
  },
  emits: ['close', 'show-coworkers'],
  setup(props) {
    const { isMdMax } = useBreakpoints()
    const search = ref('')
    const showCoworkersComputed = computed(() => !search.value && !props.isDraft && !props.isSimulateTicket)
    const showSearch = ref(false)
    const sidebarSections: SidebarSectionType[] = [
      {
        label: 'Ticket',
        expanded: true,
      },
      {
        label: 'Contact',
      },
      {
        label: 'Contact notes',
        facet: props.isDraft || props.isSimulateTicket ? 0 : 3,
      },
      {
        label: 'Recent activities',
        facet: props.isDraft || props.isSimulateTicket ? 0 : 4,
      },
    ]
    const computedSidebarSections = computed((): SidebarSectionType[] => search.value ? search.value.length > 2 ? [] : sidebarSections.slice(0, 1) : sidebarSections)
    const hideSearch = () => {
      showSearch.value = false
      search.value = ''
    }

    return {
      hideSearch,
      detailsSidebarActivities,
      showSearch,
      computedSidebarSections,
      sidebarSections,
      isMdMax,
      search,
      showCoworkersComputed,
    }
  },
})
