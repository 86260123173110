import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_fields_container = _resolveComponent("tm-fields-container")!
  const _component_tm_system_info = _resolveComponent("tm-system-info")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.searchState)
      ? (_openBlock(), _createBlock(_component_tm_fields_container, _mergeProps({
          key: 0,
          modelValue: _ctx.statusValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.statusValue) = $event)),
          label: "Status",
          type: "selectStatus",
          options: _ctx.statusOptions
        }, _ctx.commonProps), null, 16, ["modelValue", "options"]))
      : _createCommentVNode("", true),
    (!_ctx.searchState)
      ? (_openBlock(), _createBlock(_component_tm_fields_container, _mergeProps({
          key: 1,
          modelValue: _ctx.assigneeValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.assigneeValue) = $event)),
          label: "Assignee",
          type: "assignee",
          size: "small",
          "avatar-size": "xxSmall",
          "empty-placeholder": "No assignee",
          options: _ctx.assigneeOptions
        }, _ctx.commonProps), null, 16, ["modelValue", "options"]))
      : _createCommentVNode("", true),
    (!_ctx.searchState)
      ? (_openBlock(), _createBlock(_component_tm_fields_container, _mergeProps({
          key: 2,
          modelValue: _ctx.priorityValue,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.priorityValue) = $event)),
          label: "Priority",
          type: "selectPriority",
          options: _ctx.priorityOptions
        }, _ctx.commonProps), null, 16, ["modelValue", "options"]))
      : _createCommentVNode("", true),
    (!_ctx.searchState && !_ctx.isSimulateTicket)
      ? (_openBlock(), _createBlock(_component_tm_fields_container, _mergeProps({
          key: 3,
          modelValue: _ctx.carbonCopy,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.carbonCopy) = $event)),
          label: "CCs",
          type: "carbonCopy"
        }, _ctx.commonProps, { options: _ctx.carbonCopyOptions }), null, 16, ["modelValue", "options"]))
      : _createCommentVNode("", true),
    (!_ctx.searchState)
      ? (_openBlock(), _createBlock(_component_tm_fields_container, _mergeProps({
          key: 4,
          modelValue: _ctx.date,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.date) = $event)),
          label: "Due date",
          type: "datepicker",
          placeholder: "Add dute date",
          "hide-calendar-icon": "",
          "sidebar-paddings": ""
        }, _ctx.commonProps), null, 16, ["modelValue"]))
      : _createCommentVNode("", true),
    (!_ctx.searchState)
      ? (_openBlock(), _createBlock(_component_tm_fields_container, _mergeProps({
          key: 5,
          modelValue: _ctx.tagsValue,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.tagsValue) = $event)),
          label: "Tags",
          type: "tags",
          "one-line": "",
          "sidebar-paddings": "",
          size: "small",
          "hide-input": "",
          options: _ctx.tagsOptions,
          placeholder: "Add tags"
        }, _ctx.commonProps), null, 16, ["modelValue", "options"]))
      : _createCommentVNode("", true),
    _createVNode(_component_tm_system_info, { items: _ctx.systemInfo }, null, 8, ["items"])
  ]))
}