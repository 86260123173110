import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-720a3db6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_fields_container = _resolveComponent("tm-fields-container")!
  const _component_tm_system_info = _resolveComponent("tm-system-info")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.fullName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fullName) = $event)),
      label: "Full name",
      type: "fullName"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.phones,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phones) = $event)),
      label: "Phone",
      type: "phone"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.emails,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.emails) = $event)),
      label: "Email",
      type: "email"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.jobTitle,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.jobTitle) = $event)),
      label: "Job title",
      type: "text",
      placeholder: "Add job title"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.socialMedia,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.socialMedia) = $event)),
      label: "Social media",
      type: "socialMedia"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.organization,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.organization) = $event)),
      label: "Organization",
      type: "selectOrganization",
      "show-link": !!_ctx.organization.domain,
      options: _ctx.organizationsOptions
    }, _ctx.commonProps), null, 16, ["modelValue", "show-link", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.statusValue,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.statusValue) = $event)),
      label: "Contact type",
      type: "selectStatus",
      options: _ctx.statusOptions
    }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.ownerValue,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.ownerValue) = $event)),
      label: "Contact owner",
      type: "assignee",
      size: "small",
      "avatar-size": "xxSmall",
      "empty-placeholder": "No owner",
      options: _ctx.assigneeOptions
    }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.listsValue,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.listsValue) = $event)),
      label: "Lists",
      type: "tagsList",
      "sidebar-field-margins": "",
      options: _ctx.listsOptions
    }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.segmentsValue,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.segmentsValue) = $event)),
      label: "Segments",
      type: "tagsList",
      "option-search": "label",
      placeholder: "Add segments",
      "sidebar-field-margins": "",
      options: _ctx.segmentsOptions
    }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.tagsValue,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.tagsValue) = $event)),
      label: "Tags",
      type: "tags",
      "one-line": "",
      "hide-input": "",
      options: _ctx.tagsOptions,
      placeholder: "Add tags"
    }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.timezoneCurrent,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.timezoneCurrent) = $event)),
      label: "Timezone",
      type: "selectTimezone"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.country,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.country) = $event)),
      label: "Country",
      type: "selectCountry"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_system_info, { items: _ctx.interactions }, null, 8, ["items"]),
    _createVNode(_component_tm_system_info, { items: _ctx.systemInfo }, null, 8, ["items"])
  ]))
}